export class FormatNumberValueConverter {
  toView(value?: number) {
    if (value == undefined) {
      return "";
    }
    return value.toLocaleString("fi-FI");
  }

  fromView(value: any) {
    throw new Error("Can't be performed");
  }
}
