import { autoinject } from 'aurelia-framework';
import { I18N } from 'aurelia-i18n';
import { Router } from 'aurelia-router';
import { EmailTemplateLanguage, MyHttpApi } from 'utils/api';
import { Notify } from 'utils/notify';

@autoinject
export class SessionForgotPassword {
  username?: string;

  constructor(private api: MyHttpApi, private notify: Notify, private router: Router, private i18n: I18N) {
  }

  async change() {
    if (!this.username) {
      return;
    }
    let lang = <EmailTemplateLanguage>((this.i18n.getLocale() || 'fi').toUpperCase());
    
    let success = await this.api.forgotPasswordForgotPassword({ email: this.username, lang });
    if (!success) {
      this.notify.info("actor.noSuchUser");
      return;
    }
    this.router.navigateToRoute("session/login");
  }
}
