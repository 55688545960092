// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `img.otp-code {
  margin: 1em 0;
  min-width: 200px;
  image-rendering: pixelated;
}
form {
  max-width: 30em;
  margin: auto;
}
form#verify {
  margin-top: 6em;
}
`, "",{"version":3,"sources":["webpack://./src/session/otp/init.less"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,gBAAA;EACA,0BAAA;AACF;AACA;EACE,eAAA;EACA,YAAA;AACF;AAAE;EACE,eAAA;AAEJ","sourcesContent":["img.otp-code {\n  margin: 1em 0;\n  min-width: 200px;\n  image-rendering: pixelated;\n}\nform {\n  max-width: 30em;\n  margin: auto;\n  &#verify {\n    margin-top: 6em;\n  }\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
