import { autoinject } from 'aurelia-framework';
import { Router } from 'aurelia-router';
import { MyHttpApi } from 'utils/api';

@autoinject
export class SessionLogout {
  constructor(private api: MyHttpApi, private router: Router) {
  }

  async activate() {
    await this.api.sessionLogout();
    this.api.session = undefined;
    this.router.navigateToRoute("/");
  }
}
