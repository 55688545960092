export class FormatSizeValueConverter {
  toView(value?: number) {
    if (value == undefined) {
      return "";
    }

    let orders = ["B", "K", "M", "G", "T"];
    for (let i = 0; i < orders.length; i ++) {
      let order = Math.pow(10, (i + 1) * 3);
      if (value < order) {
        const result = Math.round(value / order * 10000) / 10;
        return result.toLocaleString() + "\u202f" + orders[i];
      }
    }

    return value.toLocaleString();
  }

  fromView(value: any) {
    throw new Error("Can't be performed");
  }
}
