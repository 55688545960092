export class KeysValueConverter {
  toView(value?: {}) {
    if (!value) {
      return undefined;
    }
    return Reflect.ownKeys(value);
  }

  fromView(value: any) {
    throw new Error("Can't be performed");
  }
}
