import { autoinject, computedFrom } from 'aurelia-framework';
import { I18N } from 'aurelia-i18n';
import { Router } from 'aurelia-router';
import { BindingSignaler } from 'aurelia-templating-resources';
import { FinalizeOTPInitResponse, Language, MyHttpApi } from 'utils/api';
import { Notify } from 'utils/notify';


@autoinject
export class SessionOtpInit {
  private m = "";
  private otpCode?: number;
  private response?: FinalizeOTPInitResponse;
  private qrcode?: string;

  constructor(private api: MyHttpApi, private notify: Notify, private router: Router, private i18n: I18N, private bindingSignaler: BindingSignaler) {
  }

  async activate(params: { m: string, lang: string }) {
    this.m = params.m;
    await this.setLocale(params.lang.toLowerCase() || "fi")
    await this.verify();
  }
  
  /** Acting as listener and handling to get translated QR code  */
  @computedFrom("i18n.i18next.language")
  get fake() {
    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    this.verify(true);
    return "";
  }

  async verify(reloadQr = false) {
    let lang = <Language>((this.i18n.getLocale() || 'fi').toUpperCase())
    this.response = await this.api.oTPVerify({
      m: this.m,
      otpCode: reloadQr ? undefined : this.otpCode,
      lang
    });
    if (!this.response) {
      return;
    }
    this.qrcode = "data:image/png;base64," + this.response.data;
    if (this.response.inUse) {
      this.notify.info("actor.otpEnabled");
      this.router.navigateToRoute("session/logout");
    }
  }

  copy() {
    if (!this.response) {
      return;
    }
    return navigator.clipboard.writeText(this.response.otpSecret);
  }
  async setLocale(locale: string) {
    await this.i18n.setLocale(locale);
    localStorage.setItem("rj-master-language", locale);
    this.bindingSignaler.signal("language");
  }
}
