import { autoinject } from 'aurelia-framework';
import { Router } from 'aurelia-router';
import { ActorWithPermissionsResponse, MyHttpApi } from 'utils/api';
import { Notify } from 'utils/notify';

@autoinject
export class SessionOwnInfo {
  private self?: ActorWithPermissionsResponse;
  

  constructor(private api: MyHttpApi, private notify: Notify, private router: Router) {
  }

  async activate() {
    [this.self] = await Promise.all([
      this.api.actorSelf(),
    ]);
  }

  async save() {
    if (!this.self?.actor) {
      return;
    }
    await this.api.actorUpdateSelf({
      firstName: this.self.actor.firstName,
      lastName: this.self.actor.lastName,
    });
    this.notify.info("common.updateDone");
  }
}
