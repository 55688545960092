import { FrameworkConfiguration, PLATFORM } from 'aurelia-framework';

export function configure(config: FrameworkConfiguration) {
  config.globalResources(PLATFORM.moduleName("./value-converters/empty-to-null"));
  config.globalResources(PLATFORM.moduleName("./value-converters/format-boolean"));
  config.globalResources(PLATFORM.moduleName("./value-converters/format-date"));
  config.globalResources(PLATFORM.moduleName("./value-converters/format-long-date"));
  config.globalResources(PLATFORM.moduleName("./value-converters/format-money"));
  config.globalResources(PLATFORM.moduleName("./value-converters/format-number"));
  config.globalResources(PLATFORM.moduleName("./value-converters/format-reservation-time"));
  config.globalResources(PLATFORM.moduleName("./value-converters/format-short-date"));
  config.globalResources(PLATFORM.moduleName("./value-converters/format-size"));
  config.globalResources(PLATFORM.moduleName("./value-converters/json"));
  config.globalResources(PLATFORM.moduleName("./value-converters/keys"));
}
