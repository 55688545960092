export class FormatDateValueConverter {
  toView(value?: Date) {
    if (!value) {
      return "";
    }
    return new Date(value.getUTCFullYear(), value.getUTCMonth(), value.getUTCDate(), 0, 0, 0, 0).toLocaleDateString("fi-FI");
  }

  fromView(value: any) {
    throw new Error("Can't be performed");
  }
}
