export class FormatShortDateValueConverter {
  toView(value?: Date) {
    if (!value) {
      return "";
    }
    let ddmm = value.toLocaleDateString("fi-FI").replace(/\.\d{4}$/, "");
    let hhmm = value.toLocaleTimeString("fi-FI").replace(/\.\d{1,2}$/, "");
    return ddmm + " " + hhmm;
  }

  fromView(value: any) {
    throw new Error("Can't be performed");
  }
}
