import { autoinject } from 'aurelia-framework';
import { Router } from 'aurelia-router';
import { ActorWithPermissionsResponse, MyHttpApi } from 'utils/api';
import { Notify } from 'utils/notify';

@autoinject
export class SessionChangePassword2 {
  private self?: ActorWithPermissionsResponse;
  private oldPassword?: string;
  private password?: string;
  private password2?: string;
  constructor(private api: MyHttpApi, private notify: Notify, private router: Router) {
  }

  async activate() {
    this.self = await this.api.actorSelf();
  }

  async change() {
    if (!this.password || !this.oldPassword || this.password != this.password2) {
      return;
    }
    await this.api.actorChangePasswordWithSession({ oldPassword: this.oldPassword, password: this.password });
    this.notify.info("session.passwordChanged");
    this.router.navigateToRoute("session/own-info");
  }
}
