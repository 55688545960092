import { autoinject } from 'aurelia-framework';
import { I18N } from 'aurelia-i18n';
import { Router } from 'aurelia-router';
import { BindingSignaler } from 'aurelia-templating-resources';
import { MyHttpApi } from 'utils/api';
import { Notify } from 'utils/notify';

@autoinject
export class SessionChangePassword {
  m = "";
  password?: string;
  password2?: string;
  private username?: string;

  constructor(private api: MyHttpApi, private notify: Notify, private router: Router, private i18n: I18N, private bindingSignaler: BindingSignaler) {
  }

  async activate(params: { m: string, lang: string }) {
    this.m = params.m;
    this.setLocale(params.lang.toLowerCase() || "fi");
    let tmp = await this.api.forgotPasswordGetUsernameSet({m: this.m});
    this.username = tmp.username;
  }

  async change() {
    if (!this.password || this.password != this.password2) {
      return;
    }
    await this.api.forgotPasswordSetPassword({ m: this.m, password: this.password });
    this.notify.info("session.passwordChanged");
    this.router.navigateToRoute("session/login");
  }
  setLocale(locale: string) {
    this.i18n.setLocale(locale);
    localStorage.setItem("rj-master-language", locale);
    this.bindingSignaler.signal("language");
  }
}
