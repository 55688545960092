import { autoinject } from 'aurelia-framework';
import { I18N } from 'aurelia-i18n';
import { Router } from 'aurelia-router';
import { EmailTemplateLanguage, MyHttpApi } from 'utils/api';
import { Notify } from 'utils/notify';


@autoinject
export class SessionOtpSetup {
  constructor(private api: MyHttpApi, private notify: Notify, private router: Router, private i18n: I18N) {
  }

  async initializeOtp() {
    let lang = <EmailTemplateLanguage>((this.i18n.getLocale() || 'fi').toUpperCase())
    await this.api.oTPStart({lang});
  }
  
  async removeOtp() {
    if(!confirm(this.i18n.tr("actor.otpConfirmDelete"))) {
      return;
    }
    await this.api.oTPRemoveOTP();
    this.notify.info("actor.otpDisabledRelogin");
    this.router.navigateToRoute("session/logout");
  }
}
