export class FormatReservationTimeValueConverter {
  toView(value?: number) {
    if (value == undefined) {
      return "";
    }
    const hours = Math.trunc(value);
    const minutes = 60 * (value - hours) ;
    return `${hours}h ${this.stringify(minutes)}min`;
  }

  fromView(value: any) {
    throw new Error("Can't be performed");
  }

  stringify(value: number) {
    return String(value).padStart(2, "0");
  }
}
