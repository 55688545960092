import { autoinject } from 'aurelia-framework';
import { I18N } from 'aurelia-i18n';
import { Router } from 'aurelia-router';
import { EmailTemplateLanguage, MyHttpApi, Session } from 'utils/api';
import { Notify } from 'utils/notify';

@autoinject
export class SessionLogin {
	username = "";
	password = "";
	requireOtp = false;
	otpCode?: number;

	/* Used to indicate, that we did manage to get a successfull login to MS services  */
	sessionId = "";

	constructor(private api: MyHttpApi, private router: Router, private i18n: I18N, private notify: Notify) {
	}

	/**
	 * 
	 * @param params sessionId is returned from MS auth, requireOtp means that OTP must be initialized, username is just for show
	 */
	async activate(params: { sessionId?: string, requireOtp?: string, username?: string; }) {
		if (params.requireOtp === "true") {
			this.notify.info("server.otpRequiredForAdmin");
		}
		if (params.username) {
			this.username = params.username;
		}
		if (params.sessionId) {
			this.sessionId = params.sessionId;
			let session = await this.api.aDSessionById({ sessionId: this.sessionId });
			if (session) {
				this.loginDone(session);
			} else {
				// eslint-disable-next-line @typescript-eslint/no-floating-promises
				this.setRequireOtp();
			}
		}
	}

	/** Set credentials + redir to index */
	loginDone(session: Session) {
		this.api.session = session;
		this.router.navigateToRoute("/");
	}

	// * Part of BEL login
	async otpOnlyLogin() {
		if (this.otpCode == undefined) {
			return;
		}
		const session = await this.api.aDSessionOtp({
			sessionId: this.sessionId,
			otpCode: this.otpCode,
		});
		this.loginDone(session);
	}

	// * BEL-specific MS login flow
	async msLoginFlow() {
		if (this.sessionId) {
			if (this.otpCode) {
				const session = await this.api.aDSessionOtp({
					sessionId: this.sessionId,
					otpCode: this.otpCode,
				});
				this.loginDone(session);
			}
			return;
		}
		location.href = this.api.aDSessionLoginUrl();
	}

	/** Hack to defeat element not existing when focusing for OTP */
	async setRequireOtp() {
		this.requireOtp = true;
		await new Promise(r => setTimeout(r, 50));
		let el = document.getElementById("otp-input");
		if (el) {
			el.focus();
		}
	}

	/** Whole login flow */
	async login() {
		// * This is bit bad, as we might not have MS enabled. But this is more or less always now the case
		// * If you have beladmin and do not want to use MS auth, do not add the user with @bel.fi
		// * This should be done only in dev, test and prod should always have this on
		if (this.username.endsWith("@bel.fi")) {
			return this.msLoginFlow();
		}

		// * The normal user login flow
		let lang = <EmailTemplateLanguage>((this.i18n.getLocale() || 'fi').toUpperCase());
		let session = await this.api.sessionLoginByUsername({
			lang,
			username: this.username,
			password: this.password,
			otpCode: this.requireOtp ? this.otpCode : undefined
		});
		if (session.actorId) {
			this.loginDone(session);
		} else {
			await this.setRequireOtp();
		}
	}
}
