export class FormatLongDateValueConverter {
  toView(value?: Date) {
    if (!value) {
      return "";
    }
    return value.toLocaleString("fi-FI");
  }

  fromView(value: any) {
    throw new Error("Can't be performed");
  }
}
